@import "https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
:root {
  --card-width: 250px;
  --card-height: 350px;
  --card-transition-duration: .8s;
  --card-transition-easing: ease;
}

* {
  box-sizing: border-box;
}

body, html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Catamaran, sans-serif;
  font-size: 1.125rem;
  font-weight: 200;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  display: inline-block;
}

i {
  color: #fff;
  font-size: 26px;
}

a, a:visited, a:hover {
  color: #000;
  text-decoration: none;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.navbar {
  z-index: 1000;
  background-color: #0000;
  width: 100%;
  padding: .5rem 0;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar .container {
  padding: 0 1.25rem;
}

.navbar.scrolled {
  background-color: #fff;
  box-shadow: 4px 4px 8px #0003;
}

.navbar.scrolled .hamburger > span {
  background-color: #000;
}

.navbar.scrolled.hidden {
  transform: translateY(-100%);
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__links, header .button-link {
  display: none;
}

.logo {
  justify-content: center;
  align-items: center;
  width: 42px;
  display: flex;
}

.hamburger {
  cursor: pointer;
}

.hamburger > span {
  transform-origin: 3px 1px;
  background-color: #fff;
  width: 26px;
  height: 2px;
  transition: all .3s;
  display: block;
}

.hamburger > span:not(:first-child) {
  margin-top: 5px;
}

.navbar.open .hamburger > span:first-child {
  transform: rotate(45deg);
}

.navbar.open .hamburger > span:nth-child(2) {
  opacity: 0;
}

.navbar.open .hamburger > span:last-child {
  transform: rotate(-45deg);
}

.overlay {
  z-index: 2;
  background-image: linear-gradient(#000, #0000);
  position: fixed;
  inset: 0;
}

.header__menu {
  color: #000;
  z-index: 3;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  width: calc(100% - 2.75rem);
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-in {
  animation: .25s forwards fade-in;
}

.fade-out {
  animation: .25s forwards fade-out;
}

.lock-scroll {
  overflow: hidden !important;
}

section {
  min-height: 100vh;
}

#home .item {
  z-index: 1;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  width: 200px;
  height: 300px;
  list-style-type: none;
  transition: transform .1s, left .75s, top .75s, width .75s, height .75s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 4px 4px 8px #000;
}

#home .item:first-child, #home .item:nth-child(2) {
  box-shadow: none;
  opacity: 1;
  border-radius: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: none;
}

#home .item:nth-child(3) {
  left: 57%;
}

#home .item:nth-child(4) {
  left: calc(57% + 220px);
}

#home .item:nth-child(5) {
  left: calc(57% + 440px);
}

#home .item:nth-child(6) {
  opacity: 0;
  left: calc(57% + 660px);
}

#home .content {
  color: #fff;
  text-shadow: 0 3px 8px #00000080;
  opacity: 0;
  width: min(30vw, 400px);
  font: 400 .85rem Raleway, sans-serif;
  display: none;
  position: absolute;
  top: 50%;
  left: 3rem;
  transform: translateY(-50%);
}

#home .content .title {
  font-family: Catamaran, sans-serif;
  font-size: 36px;
  font-style: italic;
}

#home .content .description {
  margin: 1rem 0 1.5rem;
  font-size: .8rem;
  line-height: 1.7;
}

#home .content a {
  color: #fff;
  cursor: pointer;
  background-color: #0000001a;
  border: 2px solid #fff;
  border-radius: .25rem;
  width: -moz-fit-content;
  width: fit-content;
  padding: .75rem;
}

#home .item:nth-of-type(2) .content {
  animation: .75s ease-in-out .3s forwards show;
  display: block;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(75px - 50%));
  }

  100% {
    opacity: 1;
    filter: blur();
  }
}

#home .navigations {
  z-index: 5;
  -webkit-user-select: none;
  user-select: none;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

#home .navigations .btn {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0009;
  border-radius: 50%;
  height: 55px;
  margin: 0 .25rem;
  padding: .75rem;
}

#home .navigations .btn img {
  display: inline-block;
}

#home .navigations .btn:hover {
  background-color: #ffffff4d;
}

@media (width > 650px) and (width < 900px) {
  #home .content .title {
    font-size: 1rem;
  }

  #home .content .description, #home .content button {
    font-size: .7rem;
  }

  #home .item {
    width: 160px;
    height: 270px;
  }

  #home .item:nth-child(3) {
    left: 50%;
  }

  #home .item:nth-child(4) {
    left: calc(50% + 170px);
  }

  #home .item:nth-child(5) {
    left: calc(50% + 340px);
  }

  #home .item:nth-child(6) {
    opacity: 0;
    left: calc(50% + 510px);
  }
}

@media (width < 650px) {
  #home .content .title {
    font-size: .9rem;
  }

  #home .content .description {
    font-size: .65rem;
  }

  #home .content button {
    font-size: .7rem;
  }

  #home .item {
    width: 130px;
    height: 220px;
  }

  #home .item:nth-child(3) {
    left: 75%;
  }

  #home .item:nth-child(4) {
    left: calc(75% + 140px);
  }

  #home .item:nth-child(5) {
    left: calc(75% + 280px);
  }

  #home .item:nth-child(6) {
    opacity: 0;
    left: calc(75% + 420px);
  }
}

.title {
  opacity: .85;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: 900;
}

.subtitle {
  text-align: center;
  margin-top: 0;
  font-size: 18px;
}

#gallery {
  place-items: center;
  min-height: 110vh;
  padding: 1rem 0 2rem;
  display: grid;
}

#gallery .container {
  grid-gap: 1.5rem;
  grid-template-rows: 1fr 1.25fr;
  grid-template-columns: 1.25fr 1fr 1fr;
  width: 85%;
  min-height: calc(100vh - 3rem);
  margin: 1.75rem auto 0;
  display: grid;
}

#gallery .child {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  transition: all .3s;
  position: relative;
  box-shadow: 4px 4px 8px #343434;
}

#gallery .child:hover {
  transform: scale(1.05);
}

#gallery .child:first-child {
  background-image: url("tour.e635796c.jpg");
  grid-row: 1 / -1;
}

#gallery .child:nth-child(2) {
  background-image: url("booking.b6370464.jpg");
}

#gallery .child:nth-child(3) {
  background-image: url("tour.e635796c.jpg");
}

#gallery .child:nth-child(4) {
  background-image: url("booking.b6370464.jpg");
  grid-column: 2 / -1;
}

#gallery .location {
  color: #fff;
  font-family: Raleway, sans-serif;
  font-size: 36px;
  font-weight: 700;
  position: absolute;
  bottom: 1rem;
  left: 2rem;
}

#gallery .country {
  color: #fff9;
  font-family: Catamaran, sans-serif;
  font-size: 18px;
  position: absolute;
  bottom: .75rem;
  left: 2rem;
}

#explore {
  overflow: hidden;
}

#explore .container {
  justify-content: center;
  padding: .25rem 0 2rem;
  display: flex;
}

#explore .container .title {
  z-index: 10;
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: 900;
  position: relative;
  top: -37%;
}

#explore .container .title:after {
  content: "";
  background-color: #000;
  border-radius: 25px;
  width: 2.1rem;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -10px;
}

#explore .cont {
  height: 103vh;
  position: relative;
  overflow: hidden;
}

#explore .slider {
  will-change: transform;
  -webkit-user-select: none;
  user-select: none;
  height: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
}

#explore .slider.animation, #explore .slider.animation .slide__darkbg, #explore .slider.animation .slide__text, #explore .slider.animation .slide__letter {
  transition: transform .75s ease-in-out;
}

#explore .slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#explore .slide--1 {
  left: 0%;
}

#explore .slide--2 {
  left: 100%;
}

#explore .slide--3 {
  left: 200%;
}

#explore .slide--4 {
  left: 300%;
}

#explore .slide--5 {
  left: 400%;
}

#explore .slide__darkbg {
  will-change: transform;
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
}

#explore .slide__text-wrapper {
  z-index: 15;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

#explore .slide__letter {
  color: #000;
  z-index: 2;
  will-change: transform;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 50vw;
  font-weight: 800;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  -webkit-text-fill-color: transparent !important;
  -webkit-background-clip: text !important;
}

#explore .slide__text {
  text-transform: uppercase;
  letter-spacing: 12px;
  color: #fff;
  will-change: transform;
  -webkit-user-select: none;
  user-select: none;
  font-size: 8vw;
  font-weight: 800;
  transform: translate3d(0, 0, 0);
}

#explore .slide__text:nth-child(odd) {
  z-index: 2;
}

#explore .slide__text:nth-child(2n) {
  z-index: 1;
}

#explore .slide--1__darkbg {
  will-change: transform;
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--1-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  left: 0%;
  transform: translate3d(0, 0, 0);
}

#explore .slide--1__darkbg:after {
  content: "";
  background-color: #0b0f27d4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#explore .slide--1__letter {
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--1-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
}

#explore .slide--2__darkbg {
  will-change: transform;
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--2-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  left: -50%;
  transform: translate3d(0, 0, 0);
}

#explore .slide--2__darkbg:after {
  content: "";
  background-color: #0b0f27d4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#explore .slide--2__letter {
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--2-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
}

#explore .slide--3__darkbg {
  will-change: transform;
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--3-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  left: -100%;
  transform: translate3d(0, 0, 0);
}

#explore .slide--3__darkbg:after {
  content: "";
  background-color: #0b0f27d4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#explore .slide--3__letter {
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--3-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
}

#explore .slide--4__darkbg {
  will-change: transform;
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--4-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  left: -150%;
  transform: translate3d(0, 0, 0);
}

#explore .slide--4__darkbg:after {
  content: "";
  background-color: #0b0f27d4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#explore .slide--4__letter {
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--4-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
}

#explore .slide--5__darkbg {
  will-change: transform;
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--5-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  left: -200%;
  transform: translate3d(0, 0, 0);
}

#explore .slide--5__darkbg:after {
  content: "";
  background-color: #0b0f27d4;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#explore .slide--5__letter {
  background-color: #0000;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/537051/city--5-min-min.jpg");
  background-position: 0, 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
}

#explore .nav {
  z-index: 10;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

#explore .nav__slide {
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-left: 10px;
  display: inline-block;
  position: relative;
}

@media screen and (width <= 400px) {
  #explore .nav__slide {
    width: 22px;
    height: 22px;
  }
}

#explore .nav__slide:hover:after {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

#explore .nav__slide:after {
  content: "";
  opacity: 0;
  background-color: #fff;
  border-radius: 50%;
  width: 75%;
  height: 75%;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

#explore .nav__slide--1 {
  margin-left: 0;
}

#explore .nav-active:after {
  opacity: 1;
  transform: translate(-50%, -50%)scale(1);
}

#abouts {
  grid-gap: 1rem;
  grid-template-rows: auto auto;
  place-items: center;
  padding: 1rem 0;
  display: grid;
}

#abouts .container {
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  gap: 2rem;
  width: 85%;
  display: grid;
}

#abouts .flex-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

#abouts .children {
  text-align: center;
  border-radius: 20px;
  width: calc(50% - 1rem);
  padding: 1rem;
  box-shadow: 4px 4px 8px #0003;
}

#apps {
  grid-template-columns: 1fr 1fr;
  place-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0 1rem;
  display: grid;
}

#apps img {
  aspect-ratio: 1 / 2;
  border-radius: 20px;
  height: 490px;
  box-shadow: 4px 4px 8px #0003;
}

#apps .text {
  text-align: center;
  font-size: 34px;
  font-weight: 600;
}

#apps .flex {
  justify-content: center;
  align-items: center;
  display: flex;
}

#apps .app-btn {
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  border-radius: 5px;
  width: 45%;
  max-width: 160px;
  margin: 20px 10px;
  font-family: Lucida Grande, sans-serif;
  font-size: 10px;
  text-decoration: none;
}

#apps .app-btn.blu {
  background-color: #bd1636;
  transition: all .2s linear;
}

#apps .app-btn.blu:hover {
  box-shadow: 4px 4px 8px #000;
}

#apps .app-btn i {
  text-align: center;
  width: 20%;
  margin-right: 7px;
  font-size: 28px;
}

#apps .app-btn .big-txt {
  text-transform: capitalize;
  font-size: 17px;
}

#contact {
  text-align: center;
  color: #fff;
  background-image: url("tour.e635796c.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  place-items: center;
  min-height: 100vh;
  display: grid;
}

#contact:before {
  content: "";
  opacity: .7;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
}

#contact .contact {
  cursor: pointer;
  color: #fff;
  background-color: #bd1636;
  border: 1px solid #bd1636;
  border-radius: 100px;
  margin-top: 2rem;
  padding: .75rem 1.4rem;
  font-size: 13px;
  transition: all .2s;
}

#contact .contact:hover {
  opacity: .8;
  box-shadow: 4px 4px 8px #000;
}

.footer-content {
  color: #fff;
  background-color: #bd1636;
  padding: 0 5% 1rem;
  font-size: 17px;
}

.footer-content .category {
  font-size: 24px;
}

.footer-content .container {
  grid-template-columns: 1fr;
  justify-items: start;
  width: 80%;
  margin: 0 auto;
  display: grid;
}

.footer-content .navigation {
  text-align: left;
  width: 100%;
  padding-left: 0;
  list-style: none;
}

.footer-content .navigation a, .footer-content .navigation a:visited, .footer-content .navigation a:active {
  color: #ffffffe6;
  transition: color .2s;
}

.footer-content .navigation a:hover, .footer-content .navigation a:visited:hover, .footer-content .navigation a:active:hover {
  color: #000;
}

.footer-content .navigation li {
  justify-content: left;
  align-items: center;
  gap: 1rem;
  margin: 0 auto .75rem;
  display: flex;
}

.footer-content .navigation li img {
  width: 26px;
  height: 26px;
  display: block;
}

.footer-content .fieldInput {
  background-color: #fffffff2;
  border: 1px solid #fff3;
  border-radius: 35px;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 3rem auto auto;
  padding-top: 0;
  padding-bottom: 0;
  transition: border-color .2s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 4px 4px 8px #000;
}

.footer-content .fieldInput:hover, .footer-content .fieldInput:focus-within {
  border: 1px solid #ffffff80;
}

.footer-content .fieldInput .form-input {
  background: none;
  border: none;
  padding: 0 2px;
}

.footer-content .fieldInput .form-input:focus {
  outline: none;
}

.footer-content .fieldInput .form-submit {
  cursor: pointer;
  color: #fff;
  background-color: #bd1636;
  border: 1px solid #fff3;
  border-radius: 100px;
  height: 38px;
  padding: 0 1.2rem;
  font-size: 12px;
  transition: all .2s;
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.footer-content .fieldInput .form-submit:hover {
  box-shadow: 5px -2px 81px 1px #000;
}

.footer-content .social_profile {
  margin-top: 40px;
}

.footer-content .social_profile .list {
  justify-content: space-evenly;
  align-items: center;
  gap: .8rem;
  display: flex;
}

.footer-content .social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.footer-content .social_profile ul li a {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff3;
  border-radius: 5rem;
  width: 50px;
  height: 50px;
  line-height: 3.2rem;
  transition: all .3s;
  display: block;
}

.footer-content .social_profile ul li a:hover {
  border: 0;
  box-shadow: 4px 4px 8px #000;
}

.footer-content .copyright {
  color: #fff;
  text-align: center;
  opacity: .8;
  border-top: 1px solid #fff6;
  width: 87.5%;
  margin: 1rem auto 0;
  padding-top: 1.5rem;
  font-size: 16px;
}

.footer-content .copyright a {
  color: #fff;
  cursor: pointer;
  transition: all .2s;
}

.footer-content .copyright a:hover {
  color: #000;
}

@media (width >= 768px) {
  .header__links {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: .875rem;
    transition: color .3s ease-in-out;
    display: flex;
    position: relative;
  }

  .header__links a {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
  }

  .header__links a:hover {
    color: #000c;
  }

  .header__links a:after {
    content: "";
    background-color: #0000;
    border-radius: 25px;
    height: 3px;
    transition: background-color .3s ease-in-out;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
  }

  .header__links a:hover:after {
    background-color: #000c;
  }

  .navbar.scrolled a {
    color: #464853;
  }

  .hamburger, .header__menu, .overlay {
    display: none;
  }

  header .button-link {
    cursor: pointer;
    color: #fff;
    box-shadow: none;
    border: 1px solid #bd1636;
    border-radius: 10px;
    padding: .6em 1.2em;
    font-size: 15px;
    font-weight: 400;
    transition: all .3s;
    display: block;
  }

  header .button-link:hover {
    color: #fff;
    background-color: #bd1636;
    box-shadow: 4px 4px 8px #000;
  }

  header .button-link.scrolled {
    box-shadow: none;
    color: #fff;
    background-color: #bd1636;
    transition: all .3s;
  }

  header .button-link.scrolled:hover {
    box-shadow: 4px 4px 8px #000;
  }

  .logo {
    align-self: center;
    align-items: center;
    height: 100%;
    display: flex;
  }

  .nav__list {
    gap: 3rem;
    display: flex;
  }

  .footer-content .container {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    width: 100%;
  }

  .footer-content .container .category {
    opacity: .85;
    width: 100%;
  }
}

@media (width <= 768px) {
  #abouts {
    grid-gap: 0;
  }

  #abouts .container {
    grid-template-columns: 1fr;
  }

  #abouts .children {
    min-width: 250px;
  }

  #gallery .container {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }
}

@media (width <= 430px) {
  #apps {
    grid-template-columns: 1fr;
    padding: 3rem 0;
  }

  #gallery .container {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #gallery .child {
    height: 300px;
  }

  #gallery .child:first-child {
    grid-row: auto;
  }

  #gallery .child:nth-child(4) {
    grid-column: auto;
  }
}

/*# sourceMappingURL=index.86855170.css.map */
